// https://www.w3.org/Style/Examples/007/fonts.en.html
// CSS: fonts
// If none of the font names defined in a font or font-family declaration are available on the browser of the user, the browser will display the text using its default font. It's recommended to always define a generic font family for each declaration of font or font-family to get a less degraded situation than relying on the default browser font. All browsers should implement a list of generic font matching these families: Serif, Sans-serif, cursive, fantasy, Monospace.https://sonarqube.alaskaair.com/coding_rules?open=css%3AS4649&rule_key=css%3AS4649

/* You can add global styles to this file, and also import other style files */

@import './custom_styles/variables.scss';
@import './custom_styles/mobileresponsive.scss';

//Added by Bhanu
//this is imported so the mat-menu sticks where it belongs
//ref link: https://stackoverflow.com/questions/57947292/the-mat-menu-always-appears-at-page-end
@import '@angular/material/prebuilt-themes/indigo-pink.css';

html {
  display: table;
  margin: auto;
  height: 100%;
}

body {
  display: table-cell;
  vertical-align: top;
  height: 100%;
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.content {
  background: white;
  height: fit-content;

  @include respond-to(desktop) {
    width: 830px;
  }
}

.body-overall {
  background-color: $bg;
  margin: 0;
  font-family: ASCircular, Arial, Helvetica, sans-serif;
}

// site-wide style for container div holding select dropdowns
.dropdownContainer {
  display: flex;
  height: 48px;

  // needed to push horizontal underscore down a bit under the dropdown
  .mat-mdc-form-field-subscript-wrapper {
    height: 12px;
  }
}

// site-wide style for select dropdowns option items
.dropdownOption {
  width: auto;
}

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?1wxsbd') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?1wxsbd') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?1wxsbd##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  @include font-ASCircular;
}

// This style is used to remove whitespace from around outside of diaglogs
// Add to dialog using : panelClass: 'app-full-bleed-dialog'
.app-full-bleed-dialog .mat-mdc-dialog-container {
  padding: 0;
  border: 0;
  margin: 0;
}

// // This sets the default overlay color for focussed fields (affects drop-down lists)
// .mat-mdc-form-field-focus-overlay {
//   background-color: $atlas2;
// }

label {
  margin-bottom: 0.5rem;
  display: inline-block;
  height: 18px;
}

.label1 {
  color: $orca;
  font-family: Lato-Black, fantasy;
  font-size: 10px;
  font-weight: 900;
  height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  width: auto;
}

.value1 {
  color: $orca;
  font-family: Lato-Regular, fantasy;
  font-size: 14px;
  font-weight: normal;
  height: 17px;
  line-height: 24px;
  width: auto;
}

//------------------  KABOB MENU (Shared) ------------------//
.mat-mdc-menu-item {
  min-height: 36px !important; // override default of 48px
}

.mat-mdc-menu-item .mat-icon {
  font-size: 20px;
}

#kabobMenuAddIcon:before {
  content: $icon-Add;
  font-family: AlaskaAirlinesCrewSystems, fantasy;
  color: $atlas2;
  font-size: 16px;
}

#kabobMenuRefreshIcon:before {
  content: $icon-Recycle;
  font-family: AlaskaAirlinesCrewSystems, fantasy;
  color: $atlas2;
  font-size: 16px;
}

#kabobMenuDeleteIcon:before {
  content: $icon-Trash;
  font-family: AlaskaAirlinesCrewSystems, fantasy;
  color: $atlas2;
  font-size: 16px;
}

#kabobMenuAvatarIcon:before {
  content: $icon-User;
  font-family: AlaskaAirlinesCrewSystems, fantasy;
  color: $atlas2;
  font-size: 16px;
}

.kabobMenuItemText {
  display: flex;
  color: $atlas2;
  background-color: transparent;
  font-family: Lato-Bold, fantasy;
  font-size: 17px;
  font-weight: 700;
  line-height: 26px;
  height: fit-content;
  padding: 0px;
}

.kabobMenuItemTextSelected {
  &:after {
    content: '\a0\a0\a0\a0\a0\a0\a0'$icon-Checkmark;
    font-family: AlaskaAirlinesCrewSystems, fantasy;
    font-size: 14px;
  }

  color: rgb(5, 70, 135);
}
