/* Vertical Align Mixin */
@mixin vertical-align {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

/** Media Query Mixins, Breakpoints **/
@mixin respond-to($name) {
    @if $name == "mobile-plus" {
        @media all and (min-width: 25.875em ) { // 25.875em ~= 414px iPhone Plus min-width
            @content;
        }
    }
    // @else if $name == "mobile-devices" {
    //     @media all and (max-width: 48em) { // 48em ~= 768px Mobile Devices Less Than...
    //         @content;
    //     }
    // }
    @else if $name == "mobile-devices" {
        @media all and (max-width: 767px) { // 48em ~= 768px Mobile Devices Less Than...
            @content;
        }
    }
    @else if $name == "tablet" {
        @media all and (min-width: 48em) and (max-width: 63.9375em) { // 48em/64em ~= 768px/1024px iPad Air
            @content;
        }
    }
    @else if $name == "tablet-plus" {
        @media all and (min-width: 48.0625em) { // 48.0625em ~= 769px iPad Air
            @content;
        }
    }
    @else if $name == "tablet-devices" {
        @media all and (max-width: 77.9375em) { // 75em ~= 1200px
            @content;
        }
    }
    @else if $name == "laptop-plus" {
        @media all and (min-width: 64em) { // 75em ~= 1200px
            @content;
        }
    }
    // @else if $name == "desktopold" {
    //     @media all and (min-width: 64em){//78em) { // 93.75em ~= 1500px
    //         @content;
    //     }
    // }
    @else if $name == "desktop" {
        @media all and (min-width: 768px){//78em) { // 93.75em ~= 1500px
            @content;
        }
    }
    @else if $name == "IE" {
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { // Target IE 10+
            @content;
        }
    }
    @else if $name == "laptop-devices" {
        @media all and (max-width: 93.6875em) { // 75em ~= 1200px
            @content;
        }
    }
    @else if $name == "desktop-plus" {
        @media all and (min-width: 93.75em) { // 93.75em ~= 1500px
            @content;
        }
    }
    @else if $name == "mobile-landscape" { // iPhone 6+ Landscape
        @media all and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
            @content;
        }
    }
    @else if $name == "mobile-portrait" { // iPhone 6+ Landscape
        @media all and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
            @content;
        }
    }
    @else if $name == "iphone-landscape" { // iPhone 6,7,8 Landscape
        @media only screen and (min-device-width : 375px) and (max-device-width : 667px) and (orientation : landscape) {
            @content;
        }
    }
    @else if $name == "ipad-landscape" { // iPad Landscape
        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
            @content;
        }
    }

    @else if $name == "ipadpro-landscape" { // iPad pro Portrait
        @media only screen and (min-device-width: 1024px) and (max-device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
            @content;
        }
    }

    @else if $name == "iphoneX" { // iphonex
        @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
			@content;
        }
    }
    @else if $name == "iphoneX-portrait" { // iphonex portrait
        @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
			@content;
        }
    }

    @else if $name == "iphoneX-landscape" { // iphonex landscape
        @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
            @content;
        }
    }
    @else if $name == "ipad" { // ipad
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation: portrait) { 
        @content;
    }
    }
}