$icomoon-font-family: "AlaskaAirlinesCrewSystems" !default; // Only for icons, NOT any text
$icomoon-font-path: "assets/fonts" !default;

$icon-kabob: "\e939";
$icon-flyout-down: "\e935";
$icon-flyout-up: "\e936";
$icon-thumbs-up: "\e937";
$icon-thumbs-down: "\e938";
$icon-help2: "\e934";
$icon-Merge: "\e932";
$icon-icon-usb: "\e933";
$icon-Add: "\e900";
$icon-AddNote: "\e901";
$icon-AlertCritical: "\e902";
$icon-AlertInformation: "\e903";
$icon-AlertSuccess: "\e904";
$icon-AlertWarning: "\e905";
$icon-Archive: "\e906";
$icon-ArrowLeft: "\e907";
$icon-ArrowRight: "\e908";
$icon-ArrowDown: "\e909";
$icon-ArrowUp: "\e90a";
$icon-Award: "\e90b";
$icon-Block: "\e90c";
$icon-Bookmark: "\e90d";
$icon-Calendar: "\e90e";
$icon-CaretDown: "\e90f";
$icon-CaretLeft: "\e910";
$icon-CaretRight: "\e911";
$icon-CaretUp: "\e912";
$icon-Chat: "\e913";
$icon-Checkbox: "\e914";
$icon-CheckedBox: "\e915";
$icon-Checkmark: "\e916";
$icon-Close: "\e917";
$icon-Contacts: "\e918";
$icon-Crown: "\e919";
$icon-Download: "\e91a";
$icon-Edit: "\e91b";
$icon-Expand: "\e91c";
$icon-Filter: "\e91d";
$icon-Flag: "\e91e";
$icon-FlagFinish: "\e91f";
$icon-FlipFlops: "\e920";
$icon-Help: "\e921";
$icon-Instructions: "\e922";
$icon-Launch: "\e923";
$icon-Menu: "\e924";
$icon-Recycle: "\e925";
$icon-Redo: "\e926";
$icon-Reset: "\e927";
$icon-Search: "\e928";
$icon-Send: "\e929";
$icon-Settings: "\e92a";
$icon-SortAscend: "\e92b";
$icon-SortDescend: "\e92c";
$icon-StarFilled: "\e92d";
$icon-StarOutlined: "\e92e";
$icon-Tasks: "\e92f";
$icon-Trash: "\e930";
$icon-User: "\e931";

$darkblue: #00274a;
$midnight: #01426a;
$atlas: #2774ae;
$white: #ffffff;
$mist: #c8c9c7;
$shark: #6e7783;
$orca: #222222;
$misto: #f5f5f5;
$red: #e3093f;
$lorange: #faf2eb;
$dullorange: #d47f2f;
$lgreen: #b3d57d;
$lmidnight: #014268;
$latlas: #48a9c5;
$bg: #f7f7f7;
$greenChip: #00805d;
$redChip: #df0b37;
$orangeChip: #de750c;
$grey1: #626b79;
$atlas2:#0074c8;
$mist0: #000000;
$calbg: #e6e6e6;
$pristine: #00cff0;
$topaz: #69cf96;
$grey: #9fabbb;
$tire: #e5ad07;
$grass:#48850B;
$nemo:#F26135;
$lightgrey:#a8a8a8;
$blue: #054687;
$turquoise: #48CCCD;

@font-face {
  font-family: ASCircular-Light;
  src: url(../assets/fonts/ASCircular-Light.otf) format("opentype");
  font-weight: 300;
  font-display: swap;
  /* https://web.dev/font-display/?utm_source=lighthouse&utm_medium=devtools  */
}

@font-face {
  font-family: ASCircular-Book;
  src: url(../assets/fonts/ASCircular-Book.otf) format("opentype");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: ASCircular-Bold;
  src: url(../assets/fonts/ASCircular-Bold.otf) format("opentype");
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: ASCircular-Medium;
  src: url(../assets/fonts/ASCircular-Medium.otf) format("opentype");
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: ASCircular-MediumItalic;
  src: url(../assets/fonts/ASCircular-MediumItalic.otf) format("opentype");
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: ASCircular-LightItalic;
  src: url(../assets/fonts/ASCircular-LightItalic.otf) format("opentype");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: ASCircular-Italic;
  src: url(../assets/fonts/ASCircular-BookItalic.otf) format("opentype");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: ASCircular-BoldItalic;
  src: url(../assets/fonts/ASCircular-BoldItalic.otf) format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: AlaskaAirlinesCrewSystems, fantasy;
  src: url(../assets/fonts/AlaskaAirlinesCrewSystems.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: Lato-Black;
  src: url(../assets/fonts/Lato-webfont/Lato-Black.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: Lato-BlackItalic;
  src: url(../assets/fonts/Lato-webfont/Lato-BlackItalic.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: Lato-Bold;
  src: url(../assets/fonts/Lato-webfont/Lato-Bold.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: Lato-BoldItalic;
  src: url(../assets/fonts/Lato-webfont/Lato-BoldItalic.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: Lato-Italic;
  src: url(../assets/fonts/Lato-webfont/Lato-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: Lato-Light;
  src: url(../assets/fonts/Lato-webfont/Lato-Light.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: Lato-LightItalic;
  src: url(../assets/fonts/Lato-webfont/Lato-LightItalic.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: Lato-Regular;
  src: url(../assets/fonts/Lato-webfont/Lato-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: Lato-Thin;
  src: url(../assets/fonts/Lato-webfont/Lato-Thin.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: Lato-ThinItalic;
  src: url(../assets/fonts/Lato-webfont/Lato-ThinItalic.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@mixin font-ASCircular {
  font-family: ASCircular, Arial, Helvetica, sans-serif;
}

* {
  @include font-ASCircular;
}

// fonts sizes
//Cleanup Switch to one of the fonts above - (transfer from another application)
//$font-size-extra-extra-large: 24px;
$font-size-extra-large: 20px;
// $font-size-large: 16px;
// $font-size-default: 14px;
// $font-size-small: 12px;
// $font-family-default: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
// $default-spacing: 16px;
// $default-weight: 400;
